<template >
  <div class="about">
    <header id="myHeader"></header>

    <!--专业服务-->
    <!--专业服务-->
    <div>
    <div class="hs">
      <h2>专业服务</h2>
    </div>
    <!-- <div class="nav-buttons">
      <button class="button" :class="{ active: isActive('/about') }">
        <router-link to="/about">企业初创期</router-link>
      </button>
      <button class="button" :class="{ active: isActive('/about/22') }">
        <router-link to="/about/22">企业成长期</router-link>
      </button>
      <button class="button" :class="{ active: isActive('/about/33') }">
        <router-link to="/about/33">企业成熟期</router-link>
      </button>
      <button class="button" :class="{ active: isActive('/about/44') }">
        <router-link to="/about/44">增值服务</router-link>
      </button>
    </div> -->
    <div class="chantu">
      <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/left.png" style="width:55px; height:55px; margin-top:80px " />
      <div class="scroll-container">
        <div class="-conscrolltent">
          <!-- 这里放置你需要滚动查看的内容 -->
          <router-view></router-view>
          <!-- 更多内容 -->
        </div>
      </div>

      <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/right.png" style="width:55px; height:55px; margin-top:80px " />
    </div>
    <footer class="footer">
      <button class="but"  @click="select()">了解更多</button>
    </footer>
  </div>

    <!--服务优势-->
    <div style="background-color: #E9F5FF; height:600px">
      <div class="hs2">
        <h2>服务优势</h2>
      </div>
      <section class="content-section" style="margin-top:40px">
        <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/fuwuyoushi.png" style="width: 1050px; " />
      </section>

      <footer class="footer">
        <button class="but">了解更多</button>
      </footer>
    </div>

    <!--服务顾问-->
    <div class="guwen">
      <div class="hs2" style="margin-top: -5px;">
        <h2>服务顾问</h2>
      </div>
      <section class="content-section" style="margin-top:-100px">
        <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/guwen-tupian.png" style="width: 1600px; " />
      </section>

      <footer class="footer" style="margin-top:-120px">
        <button class="but">了解更多</button>
      </footer>
    </div>
    <!--服务机构-->
    <header id="myHeader1"></header>

    <header id="myHeader3"></header>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    select(){
      this.$router.push({ name: 'leibie'}) 
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

nav {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding-top: 20px;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 80px;
  margin-left: 180px;
  font-size: 14px;
  font-weight: 550; /* 使用关键字 "bold" 来加粗文本 */
}

nav ul li {
  cursor: pointer;
}

.contact {
  /* align-self: center; */
  padding-right: 70px;
}

#myHeader {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 400px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/zhuanyefuwu.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}
.el-tabs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 240px;
    margin-top: 30px
  }

.but {
  margin-top: 10px;
  padding: 10px 20px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff; /* 文本颜色，可根据需要调整 */
  border: none;
  border-radius: 4px; /* 圆角大小，可根据需要调整 */
  background-image: linear-gradient(
    to right,
    #8bc1fc,
    #298bf3
  ); /* 渐变效果，从左到右，颜色从浅蓝到深蓝 */
  cursor: pointer; /* 鼠标悬停时变为手型 */
  transition: background 0.3s ease; /* 背景渐变过渡效果 */
  margin-bottom: 50px;
}
.but:hover {
  background-image: linear-gradient(
    to right,
    #0056b3,
    #007bff
  ); /* 鼠标悬停时的渐变效果 */
}

.but:active {
  background-image: linear-gradient(
    to right,
    #003a7f,
    #004a8c
  ); /* 点击时的渐变效果 */
}

.statistics {
  display: flex;
  justify-content: space-around;
  margin-top: -80px;
}

.statistic {
  text-align: center;
}
.hs {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif; /* 设置字体 */
  margin-top: 80px;
}
h2 {
  position: relative;
  display: inline-block;
  text-align: center; /* 文本居中 */
}

h2::after {
  content: "";
  position: absolute;
  bottom: -20px; /* 下滑线与文字之间的距离 */
  left: 0;
  right: 0;
  border-bottom: 3px solid cornflowerblue; /* 自定义下滑线颜色和宽度 */
}

.content-section {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}

.footer {
  text-align: center;
  padding: 10px 0;
}
.header {
  text-align: center;
  padding: 20px;
}
.title {
  font-size: 24px;
}
.underline {
  border-bottom: 2px solid #007bff;
  display: inline-block;
}
.chanpin {
  height: 500px;
  padding: 80px;
  width: 800px;
}
.nav-buttons {
  display: flex;
  margin-top: 50px;
  margin-left: 340px;
  text-align: center;
  width: 800px;
  height: 42px;
  border: 1px solid rgb(216, 224, 240);
  border-radius: 5px;
}

.button {
  border: none;
  width: 200px;
  height: 42px;
  cursor: pointer;
  border-radius: 20px;
  justify-content: space-between;
  text-decoration: none; /* 移除下划线 */
  background-color: white;
}
.button a {
  color: black;
  height: 42px;
  text-decoration: none; /* 移除下划线 */
  border: none; /* 无边框 */
}

/* 鼠标悬停样式 */
.button a:hover {
  color: rgb(97, 150, 249); /* 悬停时文字颜色变为蓝色 */
  border-bottom: 2px solid rgb(97, 150, 249); /* 添加底部蓝色边框 */
  margin-bottom: 15px;
  padding-bottom: 15px;
}

/* 点击时样式 */
.button.active {
  /*  color: rgb(97, 150, 249);
  border-bottom: 2px solid rgb(97, 150, 249);
  margin-bottom: 15px;
  padding-bottom: 15px; */
}
.button.active a {
  color: rgb(97, 150, 249);
  border-bottom: 2px solid rgb(97, 150, 249);
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.chantu {
  margin-left: 290px;
  margin-top: 30px;
  display: flex;
}
.scroll-container {
  width: 800px;
  height: 260px;
  overflow-x: auto; /* 启用水平滚动条 */
  overflow-y: hidden; /* 禁用垂直滚动条 */  
}

.scroll-content {
   height: auto; /*内容根据需要自动调整高度 */
  /* 其他样式 */
}

#myHeader1 {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 620px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/fuwujigou.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}
.hs2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-family: Arial, sans-serif; /* 设置字体 */
  padding-top: 50px;
}
.guwen {
  width: 100%; /* 宽度占满整个容器 */
  height: 750px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/guwen-dibu.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}

#myHeader3 {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 450px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/fuwu-weiye.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}
</style>
  
 