<template>
  <div class="product">
    <header id="myHeader"></header>
    <header id="myHeader1"></header>
    <!--数字管理平台-->
    <header id="myHeader2"></header>
    <header id="myHeader3"></header>
    <header id="myHeader4"></header>
    <!-- <header id="myHeader5"></header> -->



    <header id="myHeader6"></header>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
nav {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding-top: 20px;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 80px;
  margin-left: 180px;
  font-size: 14px;
  font-weight: 550; /* 使用关键字 "bold" 来加粗文本 */
}

nav ul li {
  cursor: pointer;
}

.contact {
  /* align-self: center; */
  padding-right: 70px;
}

#myHeader {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 390px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/chanpin-shouye.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}



#myHeader1 {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 50px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/chanpin-xiao.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}
#myHeader2 {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 700px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/chanpin-11.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}

#myHeader3 {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 700px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/chanpin-22.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}
#myHeader4 {
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 700px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/chanpin-33.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}

#myHeader6{
  /* 设置header的宽度和高度，或者让它自动适应 */
  width: 100%; /* 宽度占满整个容器 */
  height: 500px; /* 设置固定高度，或者根据需要调整 */

  /* 将图片设置为背景 */
  background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/fuwu-weiye.png");

  /* 确保背景图片覆盖整个header */
  background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不重复 */

  /* 可选：如果需要，设置其他样式如边框、边距等 */
  border: none;
  margin: 0;
  padding: 0;
}
</style>
  
 